import Layout from 'layout/index';
import * as React from 'react';
import { NotFoundPageInfo } from 'sections/NotFoundPageInfo/NotFoundPageInfo';

interface IndexPageProps {
    location: {
        pathname: string;
    };
}

export default ({ location }: IndexPageProps) => {
    return (
        <Layout location={location}>
            <NotFoundPageInfo />
        </Layout>
    );
};
